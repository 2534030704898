<template>
  <div class="page">
    <Navbar title="入学资料" type="SMP" />
    <van-form @submit="submit" class="form">
      <van-field
        v-model="entrance.srCode"
        name="学籍号"
        label="学籍号"
        placeholder="请填写学籍号"
        v-if="profile.PSN_ETC_SR !== undefined"
        :required="profile.PSN_ETC_SR === 'MDR'"
        :rules="[
          {
            required: profile.PSN_ETC_SR === 'MDR',
            message: '请填写学籍号',
          },
        ]"
      />
      <van-field
        v-model="entrance.grade"
        readonly
        clickable
        name="当前年级"
        label="当前年级"
        placeholder="点击选择年级"
        @click="gradeShow = true"
        v-if="profile.PSN_ETC_GRADE !== undefined"
        :required="profile.PSN_ETC_GRADE === 'MDR'"
        :rules="[
          {
            required: profile.PSN_ETC_GRADE === 'MDR',
            message: '点击选择年级',
          },
        ]"
      />
      <van-popup :show="gradeShow" position="bottom">
        <van-picker
          :columns="grades"
          :columns-field-names="{
            text: 'name',
          }"
          @confirm="confirmGrade"
          @cancel="gradeShow = false"
        />
      </van-popup>
      <van-field
        v-model="entrance.score"
        name="考试分数"
        label="考试分数"
        placeholder="请输入考试分数"
        v-if="profile.PSN_ETC_SCORE !== undefined"
        :required="profile.PSN_ETC_SCORE === 'MDR'"
        :rules="[
          {
            required: profile.PSN_ETC_SCORE === 'MDR',
            message: '点击选择年级',
          },
        ]"
      />
      <van-field
        v-model="entrance.school"
        name="学校名称"
        label="学校名称"
        placeholder="请输入当前或毕业学校名称"
        v-if="profile.PSN_ETC_SCHOOL !== undefined"
        :required="profile.PSN_ETC_SCHOOL === 'MDR'"
        :rules="[
          {
            required: profile.PSN_ETC_SCHOOL === 'MDR',
            message: '请输入当前或毕业学校名称',
          },
        ]"
      />
      <van-field
        v-model="entrance.station"
        clickable
        name="station"
        label="户籍派出所"
        placeholder="请输入户籍派出所"
        v-if="profile.PSN_ETC_STATION !== undefined"
        :required="profile.PSN_ETC_STATION === 'MDR'"
        :rules="[
          {
            required: profile.PSN_ETC_STATION === 'MDR',
            message: '请输入户籍派出所',
          },
        ]"
      />
      <van-row>
        <van-col offset="4" span="16" class="subbtn">
          <van-button
            :color="COLOR"
            size="small"
            icon="passed"
            native-type="submit"
          >
            提交保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import { Form, Field, Picker, Popup, Area } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Loading from '../../common/Loading.vue'
import Share from '../../common/Share.vue'
export default {
  components: {
    Navbar: Navbar,
    Loading: Loading,
    Share: Share,
    [Form.name]: Form,
    [Field.name]: Field,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Area.name]: Area
  },
  data () {
    return {
      loadingShow: false,
      gradeShow: false,
      profile: {},
      personCode: '',
      grades: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级', '初一', '初二', '初三', '高一', '高二', '高三'],
      entrance: {
        srCode: '',
        grade: '',
        score: 0,
        school: '',
        station: ''
      },
      regions: {}
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    this.profile = JSON.parse(window.sessionStorage.getItem(window.const.global.PEOPLE_PROFILE_ATTR))
    this.retrieveAttr()
    this.$refs.share.default('main')
  },
  methods: {
    confirmGrade (val) {
      this.entrance.grade = val
      this.gradeShow = false
    },
    async submit () {
      this.loadingShow = true
      var attrs = [
        { key: 'PSN_ETC_SR', type: 'DTL', content: { code: this.entrance.srCode }, seq: '1' },
        { key: 'PSN_ETC_GRADE', type: 'DTL', content: { name: this.entrance.grade }, seq: '1' },
        { key: 'PSN_ETC_SCORE', type: 'DTL', content: { value: this.entrance.score }, seq: '1' },
        { key: 'PSN_ETC_SCHOOL', type: 'DTL', content: { name: this.entrance.school }, seq: '1' },
        { key: 'PSN_ETC_STATION', type: 'DTL', content: { name: this.entrance.station }, seq: '1' }
      ]
      var pd = {
        personCode: this.personCode,
        type: 'DTL',
        attrs: JSON.stringify(attrs)
      }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/editPersonAttr', this.$qs.stringify(pd))
      this.loadingShow = false
      if (res.status === '200') {
        this.$dialog.alert({
          title: '入学资料',
          message: '保存成功'
        }).then(() => {
          this.$router.go(-1)
        })
      } else {
        this.$dialog.alert({
          title: '入学资料',
          message: res.msg
        }).then(() => {
        })
      }
    },
    async retrieveAttr () {
      var pd = { personCode: this.personCode, group: 'ETC', type: 'DTL' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttrGroupList', this.$qs.stringify(pd))
      if (res.status === '200') {
        res.data.forEach(element => {
          var obj = JSON.parse(element.content)
          if (element.key === 'PSN_ETC_SR') {
            this.entrance.srCode = obj.code
          } else if (element.key === 'PSN_ETC_GRADE') {
            this.entrance.grade = obj.name
          } else if (element.key === 'PSN_ETC_SCORE') {
            this.entrance.score = obj.value
          } else if (element.key === 'PSN_ETC_SCHOOL') {
            this.entrance.school = obj.name
          } else if (element.key === 'PSN_ETC_STATION') {
            this.entrance.station = obj.name
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.step {
  text-align: left;
}
.form {
  padding: 0px 5px;
  margin-bottom: 30px;
}
.title {
  font-size: 13px;
  padding: 8px 0px 0px 10px;
  font-weight: 600;
  text-align: left;
  color: #999;
  height: 30px;
  line-height: 30px;
}
.subbtn {
  margin: 20px auto;
}
</style>
